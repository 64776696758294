import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES, MARKS } from "@contentful/rich-text-types";
import { IDidomiObject } from "@didomi/react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode, useRef, useState } from "react";
import React from "react";

import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import EditorialEntry from "../editorials/editorial-entry";
import buttonStyles from "./button.module.scss";
import HighlightedText from "./highlighted-text";
import Icon from "./icon";
import MediaAsset from "./media-asset";
import styles from "./rich-text.module.scss";

declare global {
  var Didomi: IDidomiObject;
}

type Props = {
  text: Document;
  onlyCenter?: boolean;
  linkIsClicked?(): void;
};

/*
  for more information about "rich-text-react-renderer" visit: 
  https://www.npmjs.com/package/@contentful/rich-text-react-renderer?activeTab=code => click "README.md"
 */
export default function RichText({ text, onlyCenter, linkIsClicked }: Props) {
  const breakpoint = useBreakpoint();
  const latestAlignmentRef = useRef<"start" | "center" | "end">("center");
  const router = useRouter();

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node: any, children: ReactNode) => {
        return (
          <h1
            className={classNames(
              styles.h1Title,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.h1TitleHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </h1>
        );
      },
      [BLOCKS.HEADING_2]: (node: any, children: ReactNode) => {
        return (
          <h2
            className={classNames(
              styles.h2Title,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.h2TitleHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </h2>
        );
      },
      [BLOCKS.HEADING_3]: (node: any, children: ReactNode) => {
        return (
          <h3
            className={classNames(
              styles.h3Title,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.h3TitleHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </h3>
        );
      },
      [BLOCKS.HEADING_4]: (node: any, children: ReactNode) => {
        return (
          <h4
            className={classNames(
              styles.h4Title,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.h4TitleHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </h4>
        );
      },
      [BLOCKS.HEADING_5]: (node: any, children: ReactNode) => {
        return (
          <h5
            className={classNames(
              styles.h5Title,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.h5TitleHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </h5>
        );
      },
      [BLOCKS.HEADING_6]: (node: any, children: ReactNode) => {
        return (
          <h6
            className={classNames(
              styles.h6Title,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.h6TitleHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </h6>
        );
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => {
        const childNode = Array.isArray(children) ? children[0] : children;
        let childString = childNode?.toString().trim();

        if (childString) {
          switch (childString) {
            case "[TEXTSTART]":
              latestAlignmentRef.current = onlyCenter ? "center" : "start";
              return null;
            case "[TEXTEND]":
              latestAlignmentRef.current = onlyCenter ? "center" : "end";
              return null;
            case "[TEXTCENTER]":
              latestAlignmentRef.current = "center";
              return null;
            default:
              // Continue rendering the component
              break;
          }
        }

        return (
          <p
            className={classNames(
              styles.paragraph,
              contentfulUtils.isHighlightText(children?.toString() ?? "") ? styles.paragraphHighlighted : undefined,
              latestAlignmentRef.current === "start" && styles.alignedStart,
              latestAlignmentRef.current === "end" && styles.alignedEnd
            )}
          >
            {contentfulUtils.isHighlightText(children?.toString() ?? "") ? (
              <HighlightedText text={children?.toString() ?? ""} />
            ) : (
              children
            )}
          </p>
        );
      },
      [BLOCKS.HR]: () => <hr className={styles.hrLine} />,
      [BLOCKS.UL_LIST]: (node: any, children: ReactNode) => (
        <ul
          className={classNames(
            styles.list,
            latestAlignmentRef.current === "start" && styles.alignedStart,
            latestAlignmentRef.current === "end" && styles.alignedEnd
          )}
        >
          {children}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: ReactNode) => (
        <ol
          className={classNames(
            styles.list,
            latestAlignmentRef.current === "start" && styles.alignedStart,
            latestAlignmentRef.current === "end" && styles.alignedEnd
          )}
        >
          {children}
        </ol>
      ),
      [BLOCKS.TABLE]: (node: any, children: ReactNode) => {
        if (Array.isArray(children)) {
          if (breakpoint === "mobile") {
            return (
              <div className={styles.containerTableMobile}>
                {children.slice(1).map((row: any, indexRow: number) => {
                  return (
                    <div key={`row-${indexRow}`} className={styles.table}>
                      {children[0].props.children.map((column: any, indexColumn: number) => {
                        return (
                          <div key={`column-${indexColumn}`} className={styles.innerTable}>
                            <div className={styles.titleTable}>{column.props.children[0]}</div>
                            <div className={styles.descriptionTable}>
                              {row.props.children[indexColumn].props.children[0]}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          } else if (breakpoint === "desktop") {
            return (
              <table
                className={classNames(
                  styles.table,
                  latestAlignmentRef.current === "start" && styles.alignedStart,
                  latestAlignmentRef.current === "end" && styles.alignedEnd
                )}
              >
                <thead className={styles.thead}>
                  <tr className={styles.tr}>
                    {children[0].props.children.map((column: any, index: number) => {
                      return (
                        <th className={styles.th} key={`th-${index}`}>
                          {column.props.children[0]}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {children.slice(1).map((row: any, index: number) => {
                    return (
                      <tr className={styles.tr} key={`tr-${index}`}>
                        {row.props.children.map((column: any, index: number) => {
                          return (
                            <td className={styles.td} key={`td-${index}`}>
                              {column.props.children[0]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          }
        }

        return null;
      },

      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const data = { entry: node.data.target };
        return isActiveEntry(data.entry) ? <EditorialEntry entry={data.entry} /> : null;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const data = { entry: node.data.target };
        if (!isActiveEntry(data.entry)) return null;
        return (
          <div className={styles.mediaWrapper}>
            <MediaAsset entry={data.entry} />
          </div>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        if (!isActiveEntry(node.data.target)) return null;

        enum ButtonStyles {
          black = "primary",
          white = "secondary",
        }

        switch (node.data.target.sys.contentType.sys.id) {
          case "link":
            const link = node.data.target;
            assertEditorialType<Editorials.Link>(link, "link");
            const buttonVariant = link.fields.variant ? ButtonStyles[link.fields.variant] : undefined;

            if (link.fields.url === Constants.DIDOMI_SPECIAL_LINK)
              return (
                <button
                  className={
                    buttonVariant
                      ? classNames(
                          [buttonStyles[buttonVariant], buttonStyles.button],
                          styles.buttonVariant,
                          styles.button
                        )
                      : styles.button
                  }
                  onClick={() => window?.Didomi?.preferences.show()}
                >
                  {link.fields.hasUnderline ? <u>{link.fields.text}</u> : link.fields.text}
                </button>
              );

            return (
              <Link
                prefetch={false}
                className={
                  buttonVariant
                    ? classNames(
                        [buttonStyles[buttonVariant], buttonStyles.button],
                        styles.buttonVariant,
                        styles.button
                      )
                    : undefined
                }
                href={textUtils.sanitizeContentfulUrl(link, router)}
                target={link.fields.openOnANewTab ? "_blank" : undefined}
                scroll={!link.fields.url?.startsWith("#")}
                onClick={() => linkIsClicked?.()}
              >
                {link.fields.hasUnderline ? <u>{link.fields.text}</u> : link.fields.text}
              </Link>
            );
          case "svgIcon":
            const svgIcon = node.data.target;
            assertEditorialType<Editorials.SVGIcon>(svgIcon, "svgIcon");
            return (
              <Icon
                svgMedia={svgIcon.fields.svgMedia}
                name={svgIcon.fields.svgIconId}
                aria-hidden
                width={svgIcon.fields.width ?? 20}
                height={svgIcon.fields.height ?? 20}
                value={svgIcon.fields.value}
                className={styles.icon}
              />
            );
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => {
        const url = node.data.uri;
        return (
          <Link
            prefetch={false}
            href={url}
            target={`${url.startsWith("/") || url.startsWith("#") ? "_self" : "_blank"}`}
            scroll={!url.startsWith("#")}
            onClick={() => linkIsClicked?.()}
          >
            {children}
          </Link>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node: any, children: ReactNode) => {
        const file = node.data.target.fields?.file;

        if (!file) return null;

        return (
          <Link href={`https:${file.url}`} download={file.fileName} target="_blank">
            {children}
          </Link>
        );
      },
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => {
        //if a text is wrapped with the bold tag and contains the [GRADIENT] keyword, the gradient will be applied to the text
        const isGradient = text?.toString().includes("[GRADIENT]");
        const isGradientRegular = text?.toString().includes("[GRADIENTREGULAR]");
        if (isGradient) text = text?.toString().replace("[GRADIENT]", "");
        if (isGradientRegular) text = text?.toString().replace("[GRADIENTREGULAR]", "");
        return (
          <b
            className={classNames(
              isGradient ? styles.gradient : undefined,
              isGradientRegular ? styles.gradientRegular : undefined
            )}
          >
            {text}
          </b>
        );
      },
    },
  };

  return <>{documentToReactComponents(text, options)}</>;
}
